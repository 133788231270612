import * as React from "react"
import Main from "../../components/atoms/Main/Main";

import {StaticImage} from "gatsby-plugin-image";
import MainNav from "../../components/molecules/MainNav/MainNav";
import Link from "gatsby-link";

// markup
const IndexPage = () => {
  return (
    <Main
      poll
      footerContent={(
      <MainNav active="news"/>
    )}>
      <div></div>
      <Link to="/app/news_details"><StaticImage src="../../images/app/news.png" alt="" layout="fullWidth"/></Link>
    </Main>
  )
}

export default IndexPage
